import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep';
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import styles from './SelfRegisteredUserOnboardingWizardAddStaff.scss';
import userRoles from '../../../globals/userRoles';
import { INSTANCE_ROLES } from '../../../globals/hubConstants';
import withLocalizedContent from '../../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import PopoutPanel from '../../PopoutPanel/PopoutPanel';
import EnrolUser from '../../../routes/MySchool/tabs/StaffTab/panels/EnrolUser/EnrolUser';
import { initialiseInstance, triggerSearch } from '../../../redux/reducers/data/search.reducer';
import getPeopleInitials from '../../../structure/HubDashboardLayout/Services/getPeopleInitials';
import { getOrgStatusType } from '../../../structure/HubDashboardLayout/Services/getStatusType';
import { LOAD_ENROL_USER_SAGA } from '../../../redux/reducers/enrolUser.reducer';

function SelfRegisteredUserOnboardingWizardAddStaff({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  currentOrganizationName,
  currentOrganisationId,
  initialiseSearch,
  orgStaff,
  confirmedUserEmail,
  branch,
  loadEnrolUserSagaAction,
  triggerSearchAction
}) {
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);
  const [confirmedUserEmailArray, setConfirmedUserEmailArray] = useState([]);

  useEffect(() => {
    if (currentOrganisationId) {
      loadEnrolUserSagaAction();
      initialiseSearch(currentOrganisationId);
    }
  }, [currentOrganisationId]);

  useEffect(() => {
    if (confirmedUserEmail) setConfirmedUserEmailArray([...confirmedUserEmailArray, confirmedUserEmail]);
  }, [confirmedUserEmail]);

  const renderStaffItem = key => {
    const initials = getPeopleInitials(orgStaff[key].firstname, orgStaff[key].lastname);
    const name = `${orgStaff[key].firstname || ''} ${orgStaff[key].lastname || ''}`;
    const email = orgStaff[key].email;
    const staff = getOrgStatusType(orgStaff[key], hubContent, true);
    const isSingleUserUploaded = email && confirmedUserEmailArray.includes(email);
    return (
      <div key={key} className={styles.orgStaffContainer}>
        <div className={styles.initialsContainer}>{initials}</div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{name}</div>
          <div className={styles.email}>{email}</div>
          <div className={styles.status}>
            <SVGIcon glyph={staff.icon} />
            {isSingleUserUploaded ? hubContent.invitation_sent_self_user_registered : hubContent.sending_invitation}
          </div>
        </div>
      </div>
    );
  };

  const highlightWord = (text = '', highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`));

    if (parts.length > 1) {
      return (
        <>
          {parts[0]} <b>{parts[1]}</b> {parts.slice(2).join('')}
        </>
      );
    }

    return text;
  };

  return (
    <>
      <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_ADD_STAFF_CONTAINER" className={wizardStyles.step}>
        <WizardStep
          titleText={`${content.self_registered_user_invite_staff_title} ${currentOrganizationName}`}
          subtitleText={content.self_registered_user_invite_staff_subtitle}
        >
          <div className={styles.stepContent}>
            <Button
              dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_ADD_STAFF_BUTTON' }}
              variant="outline"
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
              text={content.self_registered_user_invite_staff_add_button}
              disabled={false}
              onClick={() => setIsAddStaffModalOpen(true)}
            />
            {Object.keys(orgStaff).length > 0 && Object.keys(orgStaff).map(key => renderStaffItem(key))}
          </div>
        </WizardStep>
        <div className={styles.informationBox}>
          <p>{content.self_registered_user_invite_staff_no_staff_to_invite}</p>
          <p>{highlightWord(content.self_registered_user_invite_staff_information_box_text, hubContent.next)}</p>
          <TextLink to={APP_CONSTANTS.HUB_SUPPORT_ADD_TEACHER_OR_OTHER_STAFF_TO_ORGANIZATION} target="_blank">
            {content.self_registered_user_invite_staff_learn_more}
          </TextLink>
        </div>
      </div>
      <PopoutPanel id="addStaffPanel" ariaLabel="add staff panel" isOpen={isAddStaffModalOpen}>
        <EnrolUser
          orgId={currentOrganisationId}
          context={APP_CONSTANTS.ORG_STAFF}
          closePanel={() => setIsAddStaffModalOpen(false)}
          onComplete={() => {
            setIsAddStaffModalOpen(false);
            triggerSearchAction(INSTANCE_ROLES.ORG_STAFF);
          }}
          hideAddToClassField
          branch={branch}
        />
      </PopoutPanel>
    </>
  );
}

SelfRegisteredUserOnboardingWizardAddStaff.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  currentOrganizationName: PropTypes.string.isRequired,
  currentOrganisationId: PropTypes.string.isRequired,
  initialiseSearch: PropTypes.func.isRequired,
  orgStaff: PropTypes.object.isRequired,
  confirmedUserEmail: PropTypes.string,
  branch: PropTypes.string,
  loadEnrolUserSagaAction: PropTypes.func.isRequired,
  triggerSearchAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'),
  connect(
    ({ organisations, identity, search, enrolUser }) => ({
      currentOrganizationName: organisations?.data[identity?.currentOrganisationId || '']?.name || '',
      currentOrganisationId: identity?.currentOrganisationId || '',
      orgStaff: search?.orgStaff?.users || {},
      confirmedUserEmail: enrolUser.confirmedUserEmail || ''
    }),
    dispatch => ({
      initialiseSearch: orgId =>
        dispatch(
          initialiseInstance(
            INSTANCE_ROLES.ORG_STAFF,
            false,
            {
              active: true,
              invited: true,
              archived: false,
              orgId,
              roles: [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN, userRoles.TEACHER]
            },
            100,
            'firstName:asc'
          )
        ),
      loadEnrolUserSagaAction: () => dispatch({ type: LOAD_ENROL_USER_SAGA }),
      triggerSearchAction: triggerSearch
    })
  )
)(SelfRegisteredUserOnboardingWizardAddStaff);
